import React from "react";
import Hero from "../../components/shared/Hero";
import Bottom from "../../components/shared/Bottom";
import AboutBhubaneswar from "../../components/aboutbhubaneswar";
function AboutBhubaneswarPage() {
  return (
    <div>
      <Hero>aboutbhubaneswar</Hero>
      <AboutBhubaneswar />
      <Bottom />
    </div>
  );
}

export default AboutBhubaneswarPage;
